.qrcodeBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 240px;
  position: relative;
  width: 240px;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  margin: 0 auto;
}
.qrcodeImg {
  width: 240px;
  height: 240px;
  border-radius: 4px;
  margin: 0 auto;
}

.expiredBox {
  color: #666;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #ffffffb0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
