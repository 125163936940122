.headerBarItem {
  padding: 4px 4px;
  color: #333;
  display: flex;
  align-items: center;
  height: 24px;
  width: 20px;
  &:hover {
    border-radius: 4px;
    background: #f5f5f5;
  }
}

.stoke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.iconBox {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px1;
}
