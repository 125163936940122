.wrap {
  min-height: 100%;
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  margin: 0 auto;
  // min-width: 1000px;
  //background-color: #f5f4f7;
  display: flex;
  flex-direction: column;

  .innerWrap {
    width: 62.5vw;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 20px;
  }

  .demoItem {
    color: #55b895;
  }

  .bannerTop {
    display: flex;
    justify-content: space-between;

    .panda {
      height: 100px;
      width: auto;
    }
  }

  .header {
    flex-shrink: 0;
  }

  .title {
    display: flex;
    justify-content: space-between;
    padding: 34px 29px 0 34px;
    height: 33px;
    box-sizing: content-box;
    margin-bottom: 29px;

    .title1 {
      line-height: 32px;
      font-size: 23px;
      font-weight: 500;
      color: #1a1b49;
    }
  }

  .recentTitle {
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 34px 29px 0 34px;
    height: 33px;
    box-sizing: content-box;

    .title1 {
      line-height: 32px;
      font-size: 23px;
      font-weight: 500;
      color: #1a1b49;
    }
  }

  .bar {
    padding: 0 30px 0 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 0 0 20px 0;

    .tab {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
    }

    .item {
      margin-right: 25px;
      width: 78px;
      font-size: 14px;
      font-weight: 500;
      color: #1a194a;
      line-height: 22px;
      text-align: center;
      padding-bottom: 8px;
      color: #333;
      cursor: pointer;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      &.active {
        font-size: 14px;
        font-weight: 600;
        color: #1a194a;
        padding-bottom: 5px;
        line-height: 22px;
        border-bottom: 3px solid #1a194a;
      }
    }
  }

  .content {
    padding: 36px 32px 0;
    flex: 1;

    .op {
      font-size: 20px;
    }

    .ab {
      opacity: 0;
    }

    :global(.ant-table-row) {
      &:hover {
        box-shadow: 0 6px 18px -8px rgb(111 113 140 / 16%),
          0 9px 28px rgb(94 93 108 / 10%);
        .ab {
          opacity: 1;
        }
      }
    }
  }
}

.opi {
  white-space: nowrap;
}

.cardList {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.bottomCardList {
  // 自由栅格布局，栅格最小宽度186px
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
}
.cardCell {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 12px;
  opacity: 1;
  padding: 16px;
  height: 220px;

  /* 纯白 */
  background: #ffffff;

  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

  .cardTitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #333333;
  }

  .cardCellDesc {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    color: #999999;
    text-wrap: wrap;
  }
}

.paginationFooter {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: flex-start;
}

.quickContent {
  display: flex;
  padding: 36px 32px 0;
  flex: 1;
}

.cardTitleBox {
  //flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

.cardTitle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-break: keep-all;
  white-space: nowrap;
  margin: 16px 0 8px;
}

.cardTitleTxt {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wordCardCell {
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-right: 15px;

  .cardInner {
    padding: 0 8px 10px;
    height: 194px;
    border-radius: 8px;
    background: #f5f8f9;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(65, 70, 75, 0.08);
  }

  .cardOp {
    flex-shrink: 0;
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
  }
}

.wordCardCellTitleBox {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
}

.wordCardDesc {
  font-size: 12px;
  line-height: 15px;
  margin-top: 14px;
  color: #999;
}

.wordCardTime {
  font-size: 12px;
  line-height: 15px;
  margin-top: 12px;
  color: #999;
}

.bannerLeft {
  flex: 1;
  .bannerLeftTop {
    .bannerLeftTopTitle {
      display: flex;
      .bannerLeftTopTitleLeft {
      }
      .bannerLeftTopTitleRight {
      }
    }

    .bannerLeftTopText {
    }
  }
}

.roundBox {
  background-color: #fff;
  margin: 0 29px 30px 34px;
  box-shadow: 0 10px 32px rgba(23, 40, 55, 0.04);
  border-radius: 16px;
  display: flex;
  padding-bottom: 20px;
}

.recentBox {
  background-color: #fff;
  margin: 0 29px 30px 34px;
  box-shadow: 0 10px 32px rgba(23, 40, 55, 0.04);
  border-radius: 16px;
  padding-bottom: 20px;
}

.searchInput {
  margin-bottom: 20px;
}

.beian {
  display: flex;
  justify-content: center;
  margin: 10px 0 30px 0;
  color: #24be58;
}

.bannerRight {
  width: 364px;
  padding: 16px;
  height: 100%;
  border: 0.5px solid #00ce72;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .bannerRightBottom {
    padding-top: 16px;
  }
}
// 移动端样式
.mobileBanner {
  width: 100%;

  color: #513c18;
  padding: 16px 20px;
  text-align: center;
  font-size: 32px;
  font-weight: 500;

  .mobileDesc {
    font-size: 16px;
    margin-top: 10px;
  }
}

@media only screen and (max-device-width: 1024px) {
  /* 在此添加针对iPad的CSS样式 */
  .wrap {
    width: 100vw;
  }
  .innerWrap {
    width: 100vw !important;
  }
  .roundBox {
    background-color: transparent;
    box-shadow: none;
    flex-direction: column;
    margin: 12px;
    display: block;

    .title {
      padding: 0;
      margin-bottom: 12px;
    }
  }

  .quickContent {
    display: block;
    padding: 0;

    .cardCell {
      width: 100%;
      box-shadow: rgb(238, 238, 238) 0px 0.99px 19.8px 0px;
      border-radius: 8px;
    }
  }

  .recentBox {
    background: transparent;
    box-shadow: none;
    margin: 12px;

    .recentTitle {
      padding: 0;
    }

    .content {
      padding: 0;
    }

    .cardInner {
      border-radius: 8px;
      box-shadow: rgb(238, 238, 238) 0px 0.99px 19.8px 0px;
    }
  }
}

/* 为iPhone设备设置特殊样式 */
@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* 在此添加针对iPhone的CSS样式 */
}
