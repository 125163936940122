.headerBarItem {
  padding: 4px 4px;
  color: #333;
  display: flex;
  align-items: center;
  height: 24px;
  width: 86px;
  &:hover {
    border-radius: 4px;
    background: #f5f5f5;
  }
}
