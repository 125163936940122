.floatInput {
  cursor: pointer;
  z-index: 9999;
  color: #666;
  position: absolute;

  left: 50%;
  transform: translateX(-50%);
}

.floatInputContent {
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.0784313725),
    0 1px 4px 0 rgba(0, 0, 0, 0.0392156863);
  white-space: nowrap;
  box-shadow: rgba(13, 20, 33, 0.13) 0px 3px 15px -3px;
}
.enterIcon {
  width: 20px;
  height: 20px;
  background-color: #cacaca;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingRow {
  display: flex;
  align-items: center;
  color: #333;
  padding: 8px 20px;
}

.loadingEsc {
  margin-left: auto;
  color: #c3c3c3;
}

.genResult {
  padding: 8px 20px;

  max-height: 300px;
  overflow-y: auto;
  white-space: pre-line;
  word-break: break-word;
  color: #333;
}

.optBar {
  margin: 10px 0 0 0;
  display: flex;
  background-color: rgb(250, 250, 250);
  padding: 10px 10px;
  .optItem {
    margin: 0 10px;
  }
}
