.payRes {
  height: 100vh;
  background: #fff;
  text-align: center;
  .payTop {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0 10px 0;
  }

  .payMoney {
    font-size: 35px;
    display: flex;
    align-items: baseline;
    justify-content: center;
    margin: 10px 0;
    .unit {
      font-size: 20px;
      margin-right: 4px;
    }
  }

  .payFooter {
    display: flex;
    justify-content: center;
    .right {
      margin: 0 0 0 10px;
    }
  }
}
