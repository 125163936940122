.pickerOptions {
  display: block;
  z-index: 1;
  width: 140px;
  display: flex;
  flex-wrap: wrap;

  .pickItem {
    border: 1px solid transparent;
    height: 16px;
    margin: 2px;
    padding: 0px;
    width: 16px;
  }
}

.stoke {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
}

.aIcon {
  fill: none;
  stroke: #444;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;
  opacity: 0.4;
  cursor: pointer;
}

.barItem {
  padding: 4px 4px;
  color: #333;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}
