.ppt {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    height: 55px;
    background: #fafafa;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
  }

  .pptBox {
    width: 100%;
  }
  .title {
    width: 100%;
    text-align: center;
    font-size: 26px;
    color: #000;
    font-weight: 700;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .card {
    width: 800px;
    padding: 30px 120px 30px 80px;
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 8px;
    display: flex;
    margin-bottom: 39px;
    background-color: #fdf5e9;
    justify-content: space-between;
    position: relative;

    .cardTitle {
      font-size: 20px;
      color: #000;
      margin-bottom: 22px;
      width: 445px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 500;
    }

    .cardDesc {
      color: #565656;
      font-size: 14px;
      margin-bottom: 11px;
      text-align: left;
      line-height: 1.5;
    }

    .cardImg {
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
      width: 206px;
      height: auto;
      pointer-events: none;
    }
  }

  .greenCard {
    background: linear-gradient(
      152deg,
      rgba(85, 195, 106, 0.1) 0%,
      rgba(242, 255, 244, 0.1) 100%
    );
  }

  .listContent {
    flex: 1;
    overflow: auto;
  }
}


.uploadDoc {
  //position: absolute;
  //top: 0;
  //left: 0;
  //right: 0;
  //bottom: 0;

  :global(.ant-upload-drag) {
    border: none !important;
  }

  :global(.ant-upload-btn) {
    padding: 0!important;
  }
}

.ant-upload-btn{}

.uploadDocTitle {
  font-size: 20px;
  color: #333;
  margin-bottom: 22px;
  width: 440px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.uploadDocDesc {
  display: flex;
  color: #666;
  font-size: 14px;
  margin-bottom: 20px;

  .mr10 {
    margin-right: 10px;
  }
}

.uploadFooter {
  display: flex;
  justify-content: center;
  .mr20 {
    margin-right: 20px;
  }
}

.table {
  margin: 20px 0 40px 0;
}

@media only screen and (max-device-width: 1024px) {
  /* 在此添加针对iPad的CSS样式 */
  .ppt {
    .pptBox {
      padding: 12px;

      .card {
        width: 100%;
        padding: 20px 15px;

        .cardTitle {
          width: 100%;
        }
      }
    }

    .progress {
      padding: 12px 8px;
      :global(.ant-steps-vertical) {
        flex-direction: row;
        transform: scale(0.7) translateX(-60px);
      }
    }
  }
}
