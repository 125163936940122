.recommendBox {
  flex: 1;

  display: flex;
  flex-direction: column;
  max-width: 800px;
  overflow: hidden;
  position: relative;
  border-left: 1px solid #ddd;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
}
.header {
  flex-shrink: 0;
  padding: 20px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
}

.scrollBox {
  flex: 1;
  overflow-y: auto;
}
.lightIcon {
  background: #fff;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04), 0 4px 10px rgba(0, 0, 0, 0.08);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.headerDesc {
  margin: 0 0 0 20px;
  font-size: 14px;
  font-weight: 700;
  color: #4b4b4b;
}

.headerSubDesc {
  line-height: 18px;
  font-size: 12px;
  color: #8e8e8e;
  margin: 5px 0 0 0;
}

.closeIcon {
  margin-left: auto;
}

.searchTitle {
  font-size: 14px;
  padding: 20px 0;
  color: #2c3e50;
}

.submitButton {
  width: 100%;
  margin: 30px 0 0 0;
}

.searchResult {
  margin: 20px 0;
  .searchResultHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px 0;
  }

  .searchRow {
    display: flex;
    padding: 20px 0 15px 0;
    border-top: 1px solid #dcdfe6;
    &:hover {
      .seeMore {
        display: block;
      }
    }
  }

  .searchRowDesc {
    display: flex;
    align-items: center;
    color: #8e8e8e;
    margin: 0 0 0 0px;
    width: 100%;
    height: 30px;
  }

  .authors {
    color: #8e8e8e;
    margin: 6px 0 4px 0px;
  }

  .seeMore {
    margin-left: auto;
    display: none;
  }

  .searchRowTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #4b4b4b !important;
  }

  .titleText {
  }

  .m4 {
    margin: 0 8px;
  }

  .export {
    cursor: pointer;
  }

  .refer {
    color: #f7941d;
    cursor: pointer;
  }

  .right {
    margin-left: 6px;
    flex: 1;
  }
}
