.price-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: baseline;
}

.create-box {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 34px;
  padding-top: 34px;
  width: 1790px;
  height: 960px;
  width: 100%;
  height: 100%;
  background: #fbfbfa;
}

.create-box .title-h1 {
  font-size: 32px;
  font-weight: 100;
  margin-bottom: 31px;
  color: #1a1b49;
  line-height: 45px;
}

.create-box .header-plan {
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: rgba(1, 2, 53, 0.9);
  line-height: 22px;
  padding-bottom: 19px;
  margin-bottom: 16px;
}

.create-box .box-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-bottom: 50px;
}

.create-box .memu-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.create-box .memu-list .plan-header {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 17px;
  color: rgba(1, 2, 53, 0.65);
  line-height: 22px;
  padding-bottom: 8px;
  margin-bottom: 27px;
}

.create-box .memu-list .plan-header:not(:last-child) {
  margin-right: 34px;
}

.create-box .memu-list .plan-header.active {
  padding-bottom: 6px;
  font-weight: 600;
  color: rgba(1, 2, 53, 0.9);
  line-height: 22px;
  border-bottom: 2px solid #191a4a;
}

.create-box .plan-header {
  font-size: 17px;
  font-weight: 600;
  color: #010235;
  line-height: 22px;
  margin-bottom: 16px;
}

.create-box .choose-plan {
  width: 855px;
  height: 602px;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(214, 214, 214, 0.61);
}

.create-box .choose-plan .info-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.create-box .choose-plan .info-content .choose-icon {
  position: absolute;
  right: 35px;
  top: 35px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.create-box .choose-plan .info-content .choose-icon .choosed-circle {
  width: 8px;
  height: 8px;
  background: #1890ff;
  border-radius: 4px;
}

.create-box .choose-plan .info-content .choose-icon:hover,
.create-box .choose-plan .info-content .choosed-icon {
  border: 1px solid #1890ff;
}

.create-box .choose-plan .info-content .item-content {
  width: 292px;
  height: 602px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 51px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-right: 1px solid rgba(214, 214, 214, 0.61);
  position: relative;
  cursor: pointer;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):nth-child(3) {
  border-right: none;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.credit-info-title {
  opacity: 0.65;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.price {
  opacity: 0.65;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.price-title {
  opacity: 0.65;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.sub-title {
  color: rgba(0, 24, 46, 0.3);
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.pre-title {
  opacity: 0.5;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.price-time {
  opacity: 0.5;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.pre-normal {
  opacity: 0.5;
}

.create-box
.choose-plan
.info-content
.item-content:not(.choosed-card):hover
.pre-bold {
  opacity: 0.39;
}

.create-box .choose-plan .info-content .credit-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 28px;
  width: 100%;
}

.create-box .choose-plan .info-content .credit-info .credit-info-title {
  height: 30px;
  font-size: 22px;
  font-weight: 600;
  color: #00182e;
  line-height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.create-box
.choose-plan
.info-content
.credit-info
.credit-info-title
.hot-tag {
  width: 41px;
  height: 18px;
  background: linear-gradient(135deg, #ff9a1e 0%, #ff4800 100%);
  border-radius: 2px;
  font-weight: 400;
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-left: 6px;
}

.create-box
.choose-plan
.info-content
.credit-info
.credit-info-title
.hot-tag
.hot-icon {
  margin-right: 3px;
}

.create-box .choose-plan .info-content .credit-info .price-desc {
  margin-top: 8px;
}

.create-box .choose-plan .info-content .credit-info .price-desc img {
  width: 20px;
  height: 20px;
  vertical-align: baseline;
}

.create-box .choose-plan .info-content .credit-info .price-desc .virtual-price {
  margin-left: 8px;
}

.create-box .choose-plan .info-content .credit-info .price {
  font-size: 18px;
  color: #00182e;
  font-weight: 600;
  line-height: 10px;
}

.create-box .choose-plan .info-content .credit-info .price-title {
  height: 56px;
  font-size: 40px;
  font-weight: 600;
  color: #00182e;
  line-height: 56px;
  padding: 0 2px 0 3px;
}

.create-box .choose-plan .info-content .credit-info .price-time {
  font-size: 18px;
  font-weight: 600;
  color: #00182e;
  line-height: 25px;
  opacity: 0.5;
}

.create-box .choose-plan .info-content .credit-info .sub-title {
  margin-top: 28px;
  font-size: 13px;
  color: rgba(0, 24, 46, 0.6);
  line-height: 18px;
}

.create-box .choose-plan .info-content .credit-info .sub-title .hook .icon {
  width: 16px;
  height: 12px;
  margin-right: 8px;
}

.create-box
.choose-plan
.info-content
.credit-info
.sub-title
.hook
.icon-type1 {
  width: 13px;
  height: 13px;
  margin-right: 9px;
}

.create-box
.choose-plan
.info-content
.credit-info
.sub-title
.hook
.icon-type2 {
  margin-right: 7px;
  width: 19px;
  height: 15px;
}

.create-box
.choose-plan
.info-content
.credit-info
.sub-title
.hook
.icon-type3 {
  width: 11px;
  height: 17px;
  margin-right: 11px;
}

.create-box
.choose-plan
.info-content
.credit-info
.sub-title
.hook
.icon-type4 {
  width: 14px;
  height: 11px;
  margin-right: 8px;
}

.create-box .choose-plan .info-content .credit-info .sub-title .pre-bold {
  font-weight: 600;
  color: rgba(0, 24, 46, 0.8);
  white-space: pre-wrap;
}

.create-box .choose-plan .info-content .credit-info .sub-title .pre-title {
  color: rgb(245 164 44 / 90%);
  font-weight: 600;
}

.create-box .choose-plan .info-content .credit-info .sub-title .pre-tooltip {
  border-bottom: 1px dashed #667583;
}

.create-box .choose-plan .info-content .credit-info .sub-title .sub {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 24, 46, 0.5);
}

.create-box .choose-plan .info-content .credit-info .sub-title .sub-desc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.create-box
.choose-plan
.info-content
.credit-info
.sub-title
.sub-desc:not(:last-child) {
  margin-bottom: 18px;
}

.create-box .choose-plan .info-content .choosed-card {
  background: rgb(255 200 24 / 4%);
  border: 2px solid #f7941d;
}

.create-box .choose-plan .info-content .choosed-card:nth-child(3) {
  border-right: 2px solid #f7941d;
}

.create-box .code-tip {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 21px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  font-size: 12px;
  color: rgba(0, 24, 46, 0.65);
  line-height: 17px;
  border-radius: 3px;
  cursor: pointer;
  margin: 10px 0 23px;
}

.create-box .code-tip_icon {
  margin-right: 4px;
}

.create-box .code-tip-hover {
  background-color: rgba(0, 24, 46, 0.05);
}

.right-price {
  margin-left: 12px;
  position: relative;
}

.switch {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  top: 249px;
  left: 0;

  display: flex;

  justify-content: space-between;

  align-items: center;
  z-index: 1;
}

.type-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 24, 46, 0.45);
  line-height: 22px;
}

.switch-content {
  width: 152px;
  height: 36px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid rgba(214, 214, 214, 0.61);
  padding: 5px 6px;
  box-sizing: border-box;
}

.switch-content .switch-btn {
  cursor: pointer;
  color: #00182e;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  height: 26px;
  text-align: center;
  border: 1px solid transparent;
  min-width: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.switch-content .switch-btn .off {
  font-size: 12px;
  font-weight: 500;
  color: #008efa;
  padding-left: 2px;
}

.switch-content .switch-btn-active {
  color: #fff;
  background: #008efa;
  border-radius: 13px;
  border-color: rgba(255, 255, 255, 0.3);
}

.switch-content .switch-btn-active .off {
  color: rgba(255, 255, 255, 0.7);
}

.choose-content {
  width: 284px;
  height: 602px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px;
  border: 1px solid rgba(214, 214, 214, 0.61);
  position: relative;
}

.choose-content .choose-info {
  padding: 0 20px 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.choose-content .choose-title {
  font-size: 17px;
  font-weight: 600;
  color: rgba(1, 2, 53, 0.9);
  line-height: 22px;
}

.choose-content .tips {
  width: 244px;
  height: 24px;
  opacity: 0.6;
  margin: 15px 0 30px;
  background: #ee89001a;
  border-radius: 4px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-content .tips p {
  font-size: 13px;
  font-family: ArialMT, Helvetica, Arial;
  color: #f59e27;
  margin-bottom: 0;
}

.choose-content .order-detail {
  width: 100%;
  margin-top: 64px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.choose-content .order-detail .type {
  font-size: 16px;
  font-weight: 400;
  color: #00182e;
  opacity: 0.45;
  text-align: left;
  margin-bottom: 82px;
  line-height: 22px;
  margin-top: 15px;
}

.choose-content .order-detail .type-detail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 12px;
}

.choose-content .order-detail .type-detail .bold-info {
  font-size: 16px;
  font-weight: 500;
  color: #00182e;
  line-height: 22px;
}

.choose-content .order-detail .type-detail .normal-info {
  font-size: 16px;
  font-weight: 400;
  color: #00182e;
  line-height: 22px;
  opacity: 0.45;
}

.choose-content .order-detail .type-detail3 {
  margin-bottom: 30px;
}

.choose-content .type-detail2 .item-line {
  width: 244px;
  height: 1px;
  background: #e6e6e6;
}

.choose-content .coupon-content-button {
  width: 224px;
  height: 38px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
}

.choose-content .type-detail2 {
  width: 100%;
  margin-bottom: 16px;
}

.choose-content .type-detail2 .flex-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-content .type-detail2 .coupon_btn {
  width: 132px;
  height: 26px;
  background: rgba(239, 246, 250, 0.65);
  text-align: center;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  color: #1a1b49;
  line-height: 26px;
  cursor: pointer;
}

.choose-content .type-detail2 .coupon_btn:hover {
  background: #eff6fa;
}

.choose-content .type-detail2 .apply-btn {
  width: 66px;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  color: #fff;
  border: none;
}

.choose-content .type-detail2 .redeem-ant-input-number {
  width: 170px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
}

.choose-content .coupon-info {
  height: 30px;
  background: #ffffff;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-content .coupon-info .coupon-info-title {
  font-size: 16px;
  font-weight: 400;
  color: #00182e;
  line-height: 22px;
  opacity: 0.45;
}

.choose-content .coupon-info .coupon-info-title img {
  vertical-align: middle;
}

.choose-content .coupon-info .coupon-info-title span {
  margin-left: 3px;
  vertical-align: middle;
}

.choose-content .coupon-info .coupon-info-price {
  font-size: 16px;
  font-weight: 500;
  color: #00182e;
  line-height: 22px;
}

.choose-content .coupon-applied {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-content .coupon-applied .delete-btn {
  cursor: pointer;
  width: 14px;
  height: 14px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAY9JREFUSEvFlr9Kw1AUxr+TIEVx0UGa6lDQQTs0FgWfwVXEzTcQFJyU4iCik6Lg7uDm4OozCIqmQ3VQ6CCmOOhS1CLJkZsaEqNp/rTcZr3fd36555577iHE+fLTeXzRLGx7EsAwCEOOjfEG4BWKco8+vkLtthYVjtoKxopzsGgJYC0qUGudTKh8hqfKZZj+f+B4cQQftALmiXiggIroAf18jMfKS9D/F5gtFUDWGoDBVDDP1ACrh6jfVP1xfgMdmL0BsNoh7MdOFljZ80M9oEjjO3a6sLPgvzYwgLKbXg+Y07dTn1lUOsSZPhtbTlk5WqcasRrl62hdxZGo3hZQ0/fjl35aLJkwjXWCuNRNezdtmES+jLJJGC0twrYWEhnTihX1nKDpZYALaWMk81FVAA8AzoYaFboD0XWswMwzsHkqXEt1Qk4/AXOmjegCpnEaC6jpywDPh2qJmrKBnz1IqfSikX4tpF986a2tJ81bQKU+TwIo/QEWUKkjhtuPpA5RLlTqmOjvvNIG4WC77+Ko/w0gRLtAhGtUnQAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 14px;
}

.choose-content .coupon-applied .delete-btn:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAAAXNSR0IArs4c6QAAAYVJREFUSEvFlr9Kw1AUxr+TxMHJSWmq6Oqioa0P0MEncFQEF0G6ijg4O4i4FsRFEB31BRw6ONqW2MW1om3RqYsOpjly04bGxjT/5DbrPd/3I+eec+4hRPmyhWWwVQSQAzAHYHYg+wDwDqAO0ipoVZ/D7GhsQDa3DrZLYF4KM3LOiZogpYxW/T4o/m/ggjEPi48BrEQC+YMa0OgIr+bb6JEfqOcL4N4pwDMJYQMZdUHqAdq1qtfnN7APKwOspYO5arJAaskLHQKdNOIq/Z/5ktiFhm03vUNgZvUyxZ2FJaSBztOOU1dOpKhGu3cSpkp1rqiHonr7QN24jVz6SamiZdrmBkE0tf19ndQnlk6Z2iLoxh6Yd2MJkwYTXQjgOZjXknrE0hE9CuAdmBcDhYQaoFSiGdtFMPLBXvQigA9gng42pBt0zLNIwIyxD/DmGOCXbODnRFIqvWgkt4X0xpc+2iYyvAVU6vMkgNIfYOcuZa4Y7jySukS5UKlronfySluER8f9P676P/PkuC6GwNqqAAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  background-size: 14px;
}

.choose-content .coupon-applied .delete-btn:hover + .coupon-info {
  background: #edf7ff;
}

.choose-content .coupon-applied .coupon-info {
  height: 30px;
  background: #ffffff;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.choose-content .coupon-applied .coupon-info .coupon-info-title {
  font-size: 16px;
  font-weight: 400;
  color: #00182e;
  line-height: 22px;
  opacity: 0.45;
}

.choose-content .coupon-applied .coupon-info .coupon-info-title img {
  vertical-align: middle;
}

.choose-content .coupon-applied .coupon-info .coupon-info-title span {
  margin-left: 3px;
  vertical-align: middle;
}

.choose-content .coupon-applied .coupon-info .coupon-info-price {
  font-size: 16px;
  font-weight: 500;
  color: #00182e;
  line-height: 22px;
}

.current-status {
  background: -webkit-gradient(
                  linear,
                  left top,
                  left bottom,
                  from(rgba(0, 0, 0, 0.036)),
                  to(rgba(35, 145, 211, 0.05))
  );
  background: linear-gradient(
                  180deg,
                  rgba(0, 0, 0, 0.036) 0%,
                  rgb(188 147 26 / 5%) 100%
  );
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid #e6e6e6;
  width: 282px;
  margin-bottom: 30px;
  padding: 26px 20px 16px;
}

.current-status .current-title {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 24, 46, 0.45);
  line-height: 22px;
  margin-bottom: 10px;
}

.current-status .current-type {
  font-size: 22px;
  font-weight: 600;
  color: rgba(0, 24, 46, 0.8);
  line-height: 30px;
  margin-bottom: 4px;
}

.current-status .current-words {
  font-size: 16px;
  font-weight: 600;
  color: rgba(52, 71, 89, 0.65);
  line-height: 22px;
  height: 49px;
}

.current-status .current-words2 {
  color: rgba(204, 149, 0, 0.8);
}

.current-status .current-words3 {
  color: rgba(236, 98, 0, 0.8);
}

.current-status .current-words4 {
  color: rgba(24, 144, 255, 0.8);
}

.current-status .expire-time-blue {
  width: 244px;
  height: 25px;
  background: rgba(230, 247, 255, 0.6);
  border-radius: 3px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #91d5ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: rgba(0, 142, 250, 0.7);
}

.current-status .expire-time {
  background: #fff0e8;
  border: 1px solid #ffb68d;
  color: #ff7427;
}

.current-status .expired-time {
  background: rgba(228, 228, 228, 0.22);
  border: 1px solid rgba(214, 214, 214, 0.61);
  color: rgba(124, 123, 120, 0.57);
}

.choose-content .coupon-content-button {
  width: 224px;
  height: 38px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 6px;
  border-color: #ffecbb;
  background: #ffecbb;
  color: #00182e;
  border: none;
}

.ant-modal {
  &.p0-ant-modal{
    .ant-modal-content {
      padding: 0 !important;
      .ant-modal-header {
        padding: 16px 24px;
        margin-bottom: 0;
      }
    }
  }
}



.w-pay {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .w-pay-alert {
    display: flex;
    align-items: center;
    width: 100%;
    background: #FEF4E8;
    padding: 6px 16px;
  }

  .title,
  .order {
    text-align: center;
  }

  .title {
    font-size: 16px;
    color: #00182e;
    margin-bottom: 8px;
    margin-top: 24px;
  }

  .order {
    font-size: 14px;
    color: #999999;
    margin-bottom: 16px;
  }

  .qr-code {
    box-sizing: border-box;
    padding: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/qr-code-border.png");
    background-size: cover;
  }

  .cost-tips {
    margin-top: 12px;
    .text {
      color: #333333;
    }

    .value {
      color: #EB5851;
      margin-left: 8px;
    }
  }

  .button-group {
    margin-top: 16px;

    //.action_btn {
    //  border-color: #ffecbb;
    //  background: #ffecbb;
    //  color: #00182e;
    //
    //  &:hover {
    //    border-color: #ffd900;
    //    background: #ffd900;
    //    color: #00182e;
    //  }
    //}
  }

  .button-group .ant-btn {
    width: 216px;
    height: 46px;
    border-radius: 8px;
    opacity: 1;
    background: #00CE72;
  }

  .tips {
    margin-top: 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    color: #999999;
    padding-bottom: 24px;
  }
}

.pay-result {
  padding-top: 36px;

  .result {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .result .tips {
    font-size: 19px;
    font-weight: 400;
    color: #00182e;
    margin: 0;
  }

  .result .image {
    height: 120px;
    margin: 50px 0 35px;
  }

  .action_btn {
    width: 224px;
    height: 38px;
    border-radius: 5px;

    border-color: #ffecbb;
    background: #ffecbb;
    color: #00182e;

    &:hover {
      border-color: #ffd900;
      background: #ffd900;
      color: #00182e;
    }
  }
}

.billingQA-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.jiayou-package {
  padding: 20px 0 0 0;

  .price-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    vertical-align: baseline;
  }

  .create-box {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 34px;
    padding-top: 34px;
    width: 1790px;
    height: 960px;
    width: 100%;
    height: 100%;
    background: #fbfbfa;
  }

  .title-h1 {
    font-size: 32px;
    font-weight: 100;
    margin-bottom: 31px;
    color: #1a1b49;
    line-height: 45px;
  }

  .header-plan {
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: rgba(1, 2, 53, 0.9);
    line-height: 22px;
    padding-bottom: 19px;
    margin-bottom: 16px;
  }

  .box-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    padding-bottom: 50px;
  }

  .box-content-left {
    display: flex;
    max-width: 866px;
    flex-wrap: wrap;
  }

  .memu-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .memu-list .plan-header {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 17px;
    color: rgba(1, 2, 53, 0.65);
    line-height: 22px;
    padding-bottom: 8px;
    margin-bottom: 27px;
  }

  .memu-list .plan-header:not(:last-child) {
    margin-right: 34px;
  }

  .memu-list .plan-header.active {
    padding-bottom: 6px;
    font-weight: 600;
    color: rgba(1, 2, 53, 0.9);
    line-height: 22px;
    border-bottom: 2px solid #191a4a;
  }

  .plan-header {
    font-size: 17px;
    font-weight: 600;
    color: #010235;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .choose-plan {
    width: 855px;
    height: 602px;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(214, 214, 214, 0.61);
  }

  .choose-plan .info-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .choose-plan .info-content .choose-icon {
    position: absolute;
    right: 35px;
    top: 35px;
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  .choose-plan .info-content .choose-icon .choosed-circle {
    width: 8px;
    height: 8px;
    background: #fffbe6;
    border-radius: 4px;
  }

  .choose-plan .info-content .choose-icon:hover,
  .choose-plan .info-content .choosed-icon {
    border: 1px solid #f7941d;
  }

  .choose-plan .info-content .item-content {
    width: 292px;
    height: 602px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 51px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid rgba(214, 214, 214, 0.61);
    position: relative;
    cursor: pointer;
  }

  .choose-plan .info-content .item-content:not(.choosed-card):nth-child(3) {
    border-right: none;
  }

  .choose-plan
  .info-content
  .item-content:not(.choosed-card):hover
  .credit-info-title {
    opacity: 0.65;
  }

  .choose-plan .info-content .item-content:not(.choosed-card):hover .price {
    opacity: 0.65;
  }

  .choose-plan
  .info-content
  .item-content:not(.choosed-card):hover
  .price-title {
    opacity: 0.65;
  }

  .choose-plan .info-content .item-content:not(.choosed-card):hover .sub-title {
    color: rgba(0, 24, 46, 0.3);
  }

  .choose-plan .info-content .item-content:not(.choosed-card):hover .pre-title {
    opacity: 0.5;
  }

  .choose-plan
  .info-content
  .item-content:not(.choosed-card):hover
  .price-time {
    opacity: 0.5;
  }

  .choose-plan
  .info-content
  .item-content:not(.choosed-card):hover
  .pre-normal {
    opacity: 0.5;
  }

  .choose-plan .info-content .item-content:not(.choosed-card):hover .pre-bold {
    opacity: 0.39;
  }

  .choose-plan .info-content .credit-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0 28px;
    width: 100%;
  }

  .choose-plan .info-content .credit-info .credit-info-title {
    height: 30px;
    font-size: 22px;
    font-weight: 600;
    color: #00182e;
    line-height: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .choose-plan .info-content .credit-info .credit-info-title .hot-tag {
    width: 41px;
    height: 18px;
    background: linear-gradient(135deg, #ff9a1e 0%, #ff4800 100%);
    border-radius: 2px;
    font-weight: 400;
    color: #fff;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    margin-left: 6px;
  }

  .choose-plan
  .info-content
  .credit-info
  .credit-info-title
  .hot-tag
  .hot-icon {
    margin-right: 3px;
  }

  .choose-plan .info-content .credit-info .price-desc {
    margin-top: 8px;
  }

  .choose-plan .info-content .credit-info .price-desc img {
    width: 20px;
    height: 20px;
    vertical-align: baseline;
  }

  .choose-plan .info-content .credit-info .price-desc .virtual-price {
    margin-left: 8px;
  }

  .choose-plan .info-content .credit-info .price {
    font-size: 18px;
    color: #00182e;
    font-weight: 600;
    line-height: 10px;
  }

  .choose-plan .info-content .credit-info .price-title {
    height: 56px;
    font-size: 40px;
    font-weight: 600;
    color: #00182e;
    line-height: 56px;
    padding: 0 2px 0 3px;
  }

  .choose-plan .info-content .credit-info .price-time {
    font-size: 18px;
    font-weight: 600;
    color: #00182e;
    line-height: 25px;
    opacity: 0.5;
  }

  .choose-plan .info-content .credit-info .sub-title {
    margin-top: 28px;
    font-size: 13px;
    color: rgba(0, 24, 46, 0.6);
    line-height: 18px;
  }

  .choose-plan .info-content .credit-info .sub-title .hook .icon {
    width: 16px;
    height: 12px;
    margin-right: 8px;
  }

  .card-wrap {
    position: relative;
    width: 257px;
    height: 170px;
    border-radius: 5px;
    box-shadow: 0 1px 5px 1px rgba(68, 90, 116, 0.25);
    box-sizing: border-box;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 24px 0 28px;
    user-select: none;
    border: 1px solid transparent;
    margin: 0 20px 10px 0;
  }

  .card-wrap.active {
    background: rgba(255, 200, 24, 0.04);
    border: 2px solid #f7941d;
  }

  .card-wrap.active .price {
    color: #f7941d;
  }

  .card-wrap .recommend {
    position: absolute;
    top: -1px;
    right: -1px;
    background: linear-gradient(111deg, #f2761a 0%, #ff8200 100%);
    border-radius: 0 5px;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    line-height: 17px;
    padding: 0 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 24px;
  }

  .card-wrap .recommend .icon {
    width: 12px;
    height: 12px;
    margin-right: 6px;
  }

  .card-wrap .words {
    font-size: 17px;
    font-weight: 400;
    color: #00182e;
    line-height: 24px;
    margin-bottom: 14px;
  }

  .card-wrap .price {
    font-size: 40px;
    font-weight: 600;
    color: #00182e;
    line-height: 56px;
    letter-spacing: 2px;
  }

  .card-wrap .price .unit {
    font-size: 22px;
    font-weight: 600;
    color: inherit;
    line-height: 30px;
    letter-spacing: 1px;
  }

  .card-wrap .price .unit.mr {
    margin-right: 3px;
  }

  .card-wrap .price-original {
    position: relative;
    font-size: 17px;
    font-weight: 400;
    color: rgba(0, 24, 46, 0.33);
    line-height: 24px;
    padding: 0 5px;
  }

  .card-wrap .price-original .unit {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
  }

  .card-wrap .price-original .unit.mr {
    margin-right: 3px;
  }

  .card-wrap .price-original:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    content: "";
    display: block;
    border-top: 1px solid rgba(0, 24, 46, 0.33);
  }

  .right-price {
    width: 284px;

    padding: 0 0 40px 0;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid rgba(214, 214, 214, 0.61);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 100px;
  }

  .right-price .choose-title {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    color: rgba(1, 2, 53, 0.9);
    line-height: 22px;
    margin-bottom: 30px;
  }

  .right-price .order-detail {
    padding: 0 20px;
    width: 100%;
  }

  .right-price .order-detail .type-detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .right-price .order-detail .type-detail .bold-info {
    font-size: 16px;
    font-weight: 500;
    color: #00182e;
    line-height: 22px;
  }

  .right-price .order-detail .type-detail .normal-info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 24, 46, 0.45);
    line-height: 22px;
  }

  .right-price .order-detail .type-detail .question-icon {
    margin-left: 3px;
  }

  .right-price .order-detail .type-detail3 .bold-info {
    font-weight: 600;
  }

  .right-price .order-detail .type-paypal {
    margin-bottom: 35px;
  }

  .right-price .type-detail4 {
    margin-top: 0 !important;
  }

  .right-price .type-detail2 {
    width: 100%;
    margin-top: 3px;
    margin-bottom: 20px;
  }

  .right-price .type-detail2 .flex-div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .right-price .type-detail2 .item-line {
    width: 244px;
    height: 1px;
    background: #e6e6e6;
  }

  .right-price .type-detail2 .coupon_btn {
    width: 132px;
    height: 26px;
    background: rgba(239, 246, 250, 0.65);
    text-align: center;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 500;
    color: #1a1b49;
    line-height: 26px;
    cursor: pointer;
  }

  .right-price .type-detail2 .coupon_btn:hover {
    background: #eff6fa;
  }

  .right-price .type-detail2 .apply-btn {
    width: 66px;
    height: 24px;
    background: #00182e;
    font-size: 14px;
    font-weight: 500;
    border-radius: 3px;
    color: #fff;
    border: none;
  }

  .right-price .type-detail2 .redeem-ant-input-number {
    width: 170px;
    height: 28px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }

  .right-price .coupon-content-button {
    width: 224px;
    height: 35px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #ffc439;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    border: none;
    color: #010235;
  }

  .right-price .coupon-content-button:hover {
    -webkit-filter: brightness(0.95);
    filter: brightness(0.95);
  }

  .user-panel {
    display: inline-block;
    vertical-align: middle;
    width: 70px;
    height: 20px;
    background: rgba(102, 182, 87, 0.1);
    border-radius: 3px;
    border: 1px solid rgba(119, 189, 105, 0.5);
    font-size: 12px;
    color: #66b657;
    line-height: 18px;
    text-align: center;
  }

  .expired-user-panel {
    background: rgba(19, 19, 91, 0.1);
    border: 1px solid #b5b5b5;
    color: #131344;
    opacity: 0.56;
  }

  .current-status {
    width: 284px;
    margin-bottom: 30px;
    padding: 27px 20px 16px;
    background: -webkit-gradient(
                    linear,
                    left top,
                    left bottom,
                    from(rgba(0, 0, 0, 0.036)),
                    to(rgba(35, 145, 211, 0.05))
    );
    background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 0.036) 0%,
                    rgba(35, 145, 211, 0.05) 100%
    );
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #e6e6e6;
  }

  .current-status .current-title {
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 24, 46, 0.45);
    line-height: 22px;
    margin-bottom: 10px;
  }

  .current-status .current-type {
    font-size: 22px;
    font-weight: 600;
    color: rgba(0, 24, 46, 0.8);
    line-height: 30px;
    margin-bottom: 4px;
  }

  .current-status .current-words {
    font-size: 16px;
    font-weight: 600;
    color: rgba(52, 71, 89, 0.65);
    line-height: 22px;
  }

  .current-status .current-words2 {
    color: rgba(204, 149, 0, 0.8);
  }

  .current-status .current-words3 {
    color: rgba(236, 98, 0, 0.8);
  }

  .current-status .current-words4 {
    color: rgba(24, 144, 255, 0.8);
  }

  .current-status .expire-time-blue {
    width: 244px;
    height: 25px;
    background: rgba(230, 247, 255, 0.6);
    border-radius: 3px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: 1px solid #91d5ff;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: rgba(0, 142, 250, 0.7);
  }

  .current-status .expire-time {
    background: #fff0e8;
    border: 1px solid #ffb68d;
    color: #ff7427;
  }

  .current-status .expired-time {
    background: rgba(228, 228, 228, 0.22);
    border: 1px solid rgba(214, 214, 214, 0.61);
    color: rgba(124, 123, 120, 0.57);
  }

  .w-pay {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 30px;

    .w-pay-alert {
      background: transparent;
      border: none;
      color: rgba(0, 24, 46, 0.65);
    }

    .title,
    .order {
      text-align: center;
      margin-bottom: 10px;
    }

    .title {
      font-size: 19px;
      color: #00182e;
      margin-top: 26px;
    }

    .order {
      font-size: 12px;
      color: #00182e;
    }

    .qr-code {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .button-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      width: 600px;
      margin-top: 40px;

      .action_btn {
        border-color: #ffecbb;
        background: #ffecbb;
        color: #00182e;

        &:hover {
          border-color: #ffd900;
          background: #ffd900;
          color: #00182e;
        }
      }
    }

    .button-group .ant-btn {
      width: 224px;
      border-radius: 5px;
      height: 38px;
      font-size: 14px;
      font-weight: 600;
      flex-grow: 0;
    }

    .tips {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      text-align: center;
      font-weight: 400;
      margin-top: 29px;
      margin-bottom: 10px;
      letter-spacing: -0.07px;
    }
  }

  .pay-result {
    padding-top: 36px;

    .result {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .result .tips {
      font-size: 19px;
      font-weight: 400;
      color: #00182e;
      margin: 0;
    }

    .result .image {
      height: 120px;
      margin: 50px 0 35px;
    }

    .action_btn {
      width: 224px;
      height: 38px;
      border-radius: 5px;

      border-color: #ffecbb;
      background: #ffecbb;
      color: #00182e;

      &:hover {
        border-color: #ffd900;
        background: #ffd900;
        color: #00182e;
      }
    }
  }
}
