.pay {
  height: 100vh;
  background: #fff;
  .payInfo {
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 45px 0 10px 0;
    font-weight: 500;
    font-size: 32px;
  }

  .payUnit {
    font-size: 25px;
    margin-right: 6px;
  }

  .orderInfo {
    text-align: center;
    padding: 0 15px;
    color: #666;
    margin: 10px 0;
    font-size: 16px;
  }

  .payButton {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
    display: block;
  }
}
