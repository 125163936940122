.login-form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 520px;
  height: 600px;

  &-header {
    height: 179px;
    width: 100%;
    padding: 0 53px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(255, 255, 255, 0.33)),
      to(rgba(218, 221, 233, 0.33))
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.33) 0%,
      rgba(218, 221, 233, 0.33) 100%
    );
    border-bottom: 1px solid rgba(0, 24, 46, 0.15);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .logo {
      width: 120px;
      margin-left: 3px;
      margin-top: 72px;
    }

    .title {
      font-size: 17px;
      font-weight: 500;
      color: #00182e;
      line-height: 40px;
    }
  }

  .login-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
    }
  }

  .login-content {
    flex: 1 1 auto;
    width: 100%;
    padding: 36px 53px 0;
    display: flex;
    flex-direction: column;

    .login-main {
      flex: 1;
    }

    .send-code-content {
      width: 100%;
      height: 100%;
      line-height: 1;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      color: rgba(0, 24, 46, 0.3);

      &.valid {
        color: #f7941d;
      }
    }
  }

  .clause {
    text-align: center;
    margin: 0;
    font-size: 12px;
    margin-top: 20px;
  }

  .clause a {
    color: rgba(0, 24, 46, 0.55);
  }

  .clause a:hover {
    color: #f7941d;
  }

  .clause .tip,
  .clause .and {
    color: rgba(26, 26, 26, 0.25);
  }

  .clause .tip {
    margin-bottom: 5px;
  }

  .foot-login {
    margin-top: 20px;
    .foot-register {
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 24, 46, 0.55);
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 31px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .foot-register .login {
      color: #f7941d;
      cursor: pointer;
    }

    .tip {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .tip:before,
    .tip:after {
      content: "";
      display: inline-block;
      border-top: 1px solid rgba(0, 24, 46, 0.25);
      flex: 1 1 auto;
      opacity: 0.66;
    }

    .tip .desc {
      font-size: 12px;
      font-weight: 400;
      color: rgba(0, 24, 46, 0.55);
      line-height: 17px;
      letter-spacing: 1px;
      padding: 0 14px;
    }

    .register-row {
      display: flex;
      justify-content: center;

      .register {
        font-size: 14px;
        line-height: 20px;
        color: #f7941d;
        cursor: pointer;
      }
    }
  }

  .back {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: #f7941d;
    line-height: 20px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .enable-btn {
    background: #f7941d !important;
    color: #fff !important;
  }

  .weixinTip {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .weixin {
    width: 14px;
    margin-right: 5px;
  }
}
