.rewriteEditor {
  flex: 1;

  display: flex;
  flex-direction: column;
  max-width: 800px;
  overflow: hidden;
  position: relative;
  border-left: 1px solid #ddd;
  padding: 0 20px;

  .title {
    font-size: 14px;
    font-weight: 500;
    color: #13134473;
    line-height: 30px;
    flex-shrink: 0;

    .input {
      font-size: 22px !important;
      border: none;
      background-color: #0000;
      border-bottom: 1px solid #1327451a;
      width: 100%;
      text-align: center;
      -webkit-box-shadow: none;
      box-shadow: none;
      font-size: 26px;
      font-weight: 600;
      padding: 40px 5px 12px;
      margin: 0 auto 24px;
      display: block;
      color: #00182e !important;
      line-height: 1.5 !important;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    position: relative;
  }

  .toolbar {
    position: fixed;
    right: 0;
    top: 100px;
    z-index: 9999;

    .toolbarBtn {
      width: 85px;
      top: 125px;
      height: 45px;
      display: flex;
      user-select: none;
      cursor: pointer;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.04), 0 4px 10px rgba(0, 0, 0, 0.08);
      border-radius: 23px 0 0 23px;
      margin-bottom: 20px;
      background-color: #fff;
    }
  }
}
