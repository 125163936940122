.workspace {
  display: flex;
  height: 100vh;
  background: #ffffff80;
  overflow: hidden;

  .left {
    width: 443px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    opacity: 1;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
}

.workspace.fold {
  .left {
    opacity: 0;
    margin-left: -443px;
  }
}

.tabs {
  border-bottom: 1px solid #1327451a;
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fbfbfa;
  display: flex;
  height: 100%;

  :global(.ant-tabs-nav-wrap) {
    padding: 16px 40px 22px;

    justify-content: space-around;
  }

  :global(.ant-tabs-nav) {
    margin-bottom: 0 !important;
  }

  :global(.ant-tabs-content),
  :global(.ant-tabs-tabpane) {
    height: 100%;
  }

  .tabitem {
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #0102354d;
    position: relative;
    min-width: 138px;
  }
}

.cards {
  position: relative;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  left: 0;
  width: 365px;
  margin: 32px auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  .arrow {
    content: "<<";
    width: 23px;
    background: #ffc64a30;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #f7941d;
    border-top: #f1f5fb;
    border-bottom: #f1f5fb;
  }

  .card {
    &:nth-child(-n + 2) {
      transition: all 0.1s ease-in, opacity 0.1s linear;
      position: absolute;
      left: -27px;
      top: 8px;
      width: 376px;
      height: 159px;
      border-radius: 5px;
      -webkit-transform: rotate(0);
      transform: rotate(0);
      opacity: 0;
      background: #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid #d4d4d4;
      -webkit-box-shadow: 0px 1px 2px 0px rgb(68 90 116 / 10%),
        0px 2px 4px 0px rgb(0 0 0 / 10%);
      box-shadow: 0 1px 2px rgb(68 90 116 / 10%), 0 2px 4px rgb(0 0 0 / 10%);
    }

    &:nth-child(2) {
      top: 0px;
      left: -17px;
      width: 376px;
      height: 171px;
      border-color: #fff;
    }

    &:nth-child(3) {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      position: relative;
      z-index: 1;
      border-radius: 6px;
      border-left: 0;
      border-bottom: 0;
      overflow: hidden;
      width: 100%;
      -webkit-box-shadow: 0px 3px 3px 1px #f3f3f3;
      box-shadow: 0 3px 3px 1px #f3f3f3;
      border-left: 9px solid #f7941d;
    }
  }

  .icon {
    width: 32px;
    height: auto;
    display: block;
    margin-right: 15px;
  }

  .content {
    width: 340px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 24px 18px 18px 20px;
    min-height: 168px;
    background: #fcfcf8;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
    border: 1px solid #fff;
    border-left: none;
  }

  .title {
    font-size: 24px;
    font-weight: 600;
    color: #010235;
    margin-bottom: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .desc {
    font-size: 12px;
    color: #01023599;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
  }

  &:hover {
    transform: translate(6px, -4px);

    .arrow {
      background: linear-gradient(270deg, #faf9ef 0%, #feba66 100%);
    }

    .card {
      &:nth-child(1) {
        opacity: 1;
        transform: rotate(-5deg);
      }

      &:nth-child(2) {
        opacity: 1;
        transform: rotate(-3deg);
      }

      &:nth-child(3) {
        box-shadow: 0 9px 5px 1px rgb(213 213 213 / 71%);
      }
    }
  }
}

.list {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  .filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px 18px 0;
    width: 100%;

    .tags {
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      margin: 18px 0 0;
      flex-wrap: wrap;
      width: 100%;

      .tag {
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        color: #1a194a;
        padding: 0 10px;
        border-radius: 14px;
        border: 1px solid #d6d6d699;
        text-align: center;
        margin-right: 8px;
        white-space: nowrap;
        margin-bottom: 14px;
        line-height: 24px;
        min-width: 40px;
        position: relative;

        &:hover {
          color: #f7941d;
          border-color: #f7941d;
        }

        &.active {
          color: #fff;
          background: #f7941d;
          border-color: #f7941d;
        }
      }
    }
  }

  .listlist {
    padding: 15px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    background-color: #eeedf2;

    .item {
      cursor: pointer;
      padding: 20px 20px 18px 36px;
      background: #fff;
      -webkit-box-shadow: 0px 1px 4px 0px rgb(68 90 116 / 25%);
      box-shadow: 0 1px 4px rgb(68 90 116 / 25%);
      border-radius: 5px;
      height: 118px;
      position: relative;
      margin-bottom: 12px;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        width: 8px;
        height: 118px;
        border-radius: 4px 0 0 4px;
        mix-blend-mode: multiply;
        background: #0000;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }

      &:hover {
        background: #ffb61807;
        transform: translate(4px, -4px);

        &::after {
          background: #f7941d;
        }
      }

      .title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 16px;
        font-weight: 600;
        color: #00182e;
        margin-bottom: 8px;

        .icon {
          width: 32px;
          height: 32px;
          margin-right: 20px;
        }
      }

      .desc {
        font-size: 14px;
        color: #00182e8c;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0;
        text-align: left;
      }

      .favorite {
        position: absolute;
        top: 9px;
        right: 9px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        width: 14px;
        height: 14px;
        color: #ffc31c;
      }
    }
  }
}

.tryout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: #010235;
  width: 394px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  line-height: 22px;
  justify-content: flex-start;

  img {
    width: 12px;
    height: auto;
    display: block;
    margin: 4px 0 0 4px;
  }

  .klist {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex: 1;

    .k {
      background: #fff;
      border-radius: 11px;
      border: 1px solid #d6d6d64d;
      font-size: 14px;
      font-weight: 600;
      color: #f7941d;
      padding: 0 12px;
      margin-left: 12px;
      margin-bottom: 12px;
      cursor: pointer;
    }
  }
}

.footer {
  display: flex;
  flex-direction: row;
  padding: 24px 0;
}

.records {
  padding: 24px;
  font-weight: 400;

  .card {
    font-size: 14px;
    border-radius: 3px;
    min-height: 126px;
    margin-bottom: 16px;
    padding: 16px;
    background: #fff;
    -webkit-box-shadow: 0px 0px 4px 0px rgb(10 42 97 / 20%);
    box-shadow: 0 0 4px rgb(10 42 97 / 20%);
    opacity: 1;

    .header {
      margin-bottom: 16px;
      font-size: 14px;
      color: #00182e54;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      .operate {
        margin-left: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;

        img {
          display: block;
          height: 24px;
          width: auto;
          margin-left: 8px;
          border-radius: 3px;
          opacity: 0.33;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
            background: #e8e8e8;
          }
        }

        &.disable img {
          cursor: not-allowed;
        }
      }
    }
  }

  .content {
    word-break: break-all;
    white-space: pre-wrap;

    .empty {
      .bar {
        width: 100%;
        background-color: rgba(128, 128, 128, 0.135);
        height: 20px;
        position: relative;
        overflow: hidden;

        // &::after {
        //     content: ' ';
        //     background-color: #fff;
        //     height: 200%;
        //     width: 10px;
        //     animation-name: roll;
        //     animation-iteration-count: infinite;
        //     position: absolute;
        //     transform: rotate(45deg);
        // }
      }
    }
  }

  .feedback {
    margin-left: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 4px;

    .icon {
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      &can {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}

.left2 {
  height: 100%;
  overflow-y: auto;
  .keywords {
    :global(.ant-select-selection-item) {
      font-weight: bold;
      color: #f7941d;
      background: rgba(255, 239, 194, 0.6196078431);
    }
  }
}

.right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  overflow: hidden;
  position: relative;

  .header {
    flex-shrink: 0;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 42px;
    padding: 0 14px;

    :global(.ql-toolbar) {
      background: #f6f6f680;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .coll {
      margin-right: 14px;

      button {
        border-radius: 0;
        background: #f6f6f680;
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fffc;
      border-radius: 3px;
      padding: 0 10px;
      height: 24px;
      font-size: 14px;
      color: #01023599;
      width: 250px;
      white-space: nowrap;
      margin: 0 24px 0 auto;
      background: #f6f6f680;
      border: 1px solid #dcdcdc80;

      & > span {
        margin-right: 10px;
      }

      .saved {
        min-width: 100px;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-align: center;
      }

      .count {
        flex: 1;
        display: flex;
        align-items: center;

        .w {
          flex: 1;
          text-align: center;
          font-weight: 400;
          color: #010235;
        }
      }
    }
  }

  .editorWrapper {
    flex: 1;
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
  }

  .editor {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    overflow: hidden;
    position: relative;
    padding: 0 20px;

    .title {
      font-size: 14px;
      font-weight: 500;
      color: #13134473;
      line-height: 30px;
      flex-shrink: 0;

      .input {
        font-size: 22px !important;
        border: none;
        background-color: #0000;
        border-bottom: 1px solid #1327451a;
        width: 100%;
        text-align: center;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-size: 26px;
        font-weight: 600;
        padding: 40px 5px 12px;
        margin: 0 auto 24px;
        display: block;
        color: #00182e !important;
        line-height: 1.5 !important;
      }
    }

    .content {
      flex: 1;
      overflow-y: auto;
      position: relative;
      & :global {
        .ql-clipboard {
          position: fixed;
          // display: none;
          // left: 50%;
          // top: 50%;
          // position: fixed !important;
          // left: 50% !important;
          // top: 50% !important;
        }
      }
    }
  }

  .footer {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(180deg, #f6f6f600 0%, #f6f6f69c 10%, #fff 100%);
    position: absolute;

    .btn {
      width: 264px;
      height: 47px;
      text-transform: uppercase;
      padding: 0;
      font-weight: 500;
      position: relative;
      transition: all 0s linear;
      background: linear-gradient(141deg, #ffd56a 0%, #ffa516 100%);

      &:not(:disabled):hover {
        .text {
          color: #000;
        }
      }
      &:disabled {
        background: rgba(128, 128, 128, 0.353);
      }
    }

    .inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #0000;
      position: relative;
      z-index: 1;

      .text {
      }

      .hotkey {
        height: 18px;
        padding: 0 4px;
        background: #ffefc29e;
        border-radius: 3px;
        opacity: 0.64;
        border: 1px solid #f7b500;
        font-size: 12px;
        color: #191735;
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
        -webkit-transform-origin: center;
        transform-origin: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }
  }

  :global {
    .quill {
      width: 100%;

      .ql-editor {
        padding: 5px 0 260px 5px;
        font-size: 16px;
        line-height: 1.7;
        color: #3d3d3d;
      }

      .ql-container {
        border: none;
      }
    }

    .rewrite-select {
      .ant-select-selector {
        padding: 0 10px 0 3px;
      }
      &.ant-select-open .ant-select-selection-item {
        color: #000;
      }

      .ant-select-arrow {
        color: #000 !important;
      }
      .ant-select-selection-placeholder {
        color: #000;
      }
    }
  }
}

@keyframes roll {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}

.rewriteIcon {
  margin-left: 5px;
}

.recommendToolbar {
  white-space: nowrap;
}
