.construct {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    height: 56px;
    background: #fafafa;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
    justify-content: center;
    flex-shrink: 0;
    color: #00ce72;
    font-size: 18px;
    font-weight: bold;

    .back {
      width: 76px;
      padding: 0 5px 0px 10px;
      border-radius: 14px;
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      line-height: 26px;
      box-sizing: border-box;
      height: 28px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .right {
      .wrapper {
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 3px;
        padding: 0 10px;
        height: 24px;
        font-size: 14px;
        color: rgba(1, 2, 53, 0.6);
        width: 250px;
        white-space: nowrap;
        margin: 0 24px 0 auto;
        background: rgba(246, 246, 246, 0.5);
        border: 1px solid rgba(220, 220, 220, 0.5);

        .count {
          color: #010235;
        }
      }
    }
  }
}

.construct .new {
  width: 62.5vw;
  display: flex;
  flex-direction: column;
  // justify-content: stretch;
  flex: 1;
  overflow: auto;
  background-image: url("../../assets/stetBg.png");
  background-size: cover;
  margin-top: 16px;
  padding: 16px;

  h1 {
    margin-top: 30px;
  }

  .step {
    margin-top: 10px;
  }

  .progress {
    padding: 20px 70px 0;
  }

  .content {
    padding: 20px 50px;

    h4 {
      color: #1a1b4c;
      line-height: 23px;
      margin: 26px 0 20px;
      width: 100%;
      text-align: center;
    }
  }

  .paging {
    display: flex;
    justify-content: center;
    height: 82px;
    align-items: center;

    button {
      margin-left: 16px;
    }
  }

  .step1 {
    .form {
      padding: 32px;
    }

    .line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      :global(.ant-form-item) {
        width: 346px;
      }
    }

    .keywords {
      :global(.ant-select-selection-item) {
        font-weight: bold;
        color: #24be58;
        background: #caefd7;
      }
    }

    .tryout {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 14px;
      font-weight: 600;
      color: #010235;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      line-height: 22px;
      justify-content: flex-start;

      img {
        width: 12px;
        height: auto;
        display: block;
        margin: 4px 0 0 4px;
      }

      .klist {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 1;

        .k {
          background: #fff;
          border-radius: 11px;
          border: 1px solid #d6d6d64d;
          font-size: 14px;
          font-weight: 600;
          color: #f7941d;
          padding: 0 12px;
          margin-left: 12px;
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }

    .submit {
      margin-top: 40px;

      :global(.ant-form-item-control-input-content) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .step2 {
    background-color: #ffffff;
    /* 分割线 */
    border: 1px solid #f0f0f0;
    .titles {
      padding: 30px 32px;
    }

    .subTitle {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
      text-align: center;
      margin-top: 20px;

      .icon {
        color: #00ce72;
        vertical-align: -2px;
        margin-left: 2px;
      }
    }

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #ffffff;
      border-radius: 3px;
      padding: 15px;
      margin-bottom: 8px;
      border: 1px solid transparent;

      &:hover {
        box-shadow: 0 1px 4px rgba(68, 90, 116, 0.25);
      }

      &.focus {
        border-color: rgba(0, 145, 255, 0.66);
      }

      &.select {
        border-color: #24be58;
        background-color: #caefd77b;
      }

      .checked {
        color: rgb(23, 163, 75);
        font-size: 24px;
        padding: 0 30px;
      }
      :global(.ant-input-data-count) {
        transform: translate3d(-12px, -3px, 0px);
        font-size: 12px;
      }
    }

    .reload {
      margin-top: 20px;
    }
  }

  .step3 {
    .inner {
      background: #fff;
      /* 分割线 */
      border: 1px solid #f0f0f0;
      border-radius: 12px;
      padding: 30px 32px;
      width: 100%;

      .itemWrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #ffffff;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 8px;
        border: 1px solid transparent;

        &:hover {
          box-shadow: 0 1px 4px rgba(68, 90, 116, 0.25);
        }

        &.focus {
          border-color: rgba(0, 145, 255, 0.66);
        }

        &.dragging {
          border-color: #f7941d;
          background-color: #f7951d21;
        }

        .icon {
          color: #999;
          margin-top: 12px;
        }

        &:hover {
          .icon {
            color: #010235;
          }
        }

        .ss {
          display: flex;
          flex: 1;
          flex-direction: column;

          ul {
            margin-top: 5px;
            color: #999;
          }

          li {
            margin-top: 4px;
          }
        }

        .input,
        .shitem {
          display: flex;
          flex: 1;
          align-items: center;

          input {
            flex: 1;
          }

          .ic {
            display: none;
            flex-shrink: 0;
          }

          &:hover .ic {
            display: block;
            opacity: 0.5;
          }

          .ic:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .step4 {
    .inner {
      background: rgba(242, 242, 245, 0.4);
      border-radius: 3px;
      padding: 30px 32px;
      width: 100%;
    }
  }
}

.successModal {
  :global(.ant-btn-primary) {
    background-color: #f7941d;

    &:hover {
      background-color: #ffb347 !important;
    }
  }
}

.wenxianDesc {
  font-size: 12px;
  color: #999;
  padding: 0 0 0 11px;
  margin: 3px 0 0 0;
}

.wenxianGuide {
  width: 600px;
  max-width: 90vw;
  img {
    width: 100%;
    margin: 4px 0;
  }
}

.summaryWrapper {
  position: relative;
}

.summaryBox {
  width: 100%;
  border: 1px solid rgb(229, 231, 235);
  margin: 32px 0 14px 0;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  border-radius: 8px;
  padding: 16px;
  max-width: 90vw;

  .summaryHeader {
    color: #333;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .summarySection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px 12px 48px;
    cursor: pointer;

    .sectionLeft {
    }
    .sectionTitle {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
    }

    .ml4 {
      margin-left: 4px;
    }
    .sectionAbstract {
      color: rgb(102, 102, 102);
      font-size: 14px;
      line-height: 21px;
      margin: 8px 0 0 0;
    }

    &:hover {
      background-color: rgb(243, 243, 245);
    }
  }

  .collaspseSection {
    padding-left: 28px;
    cursor: pointer;
    .cSectionHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0;
    }

    .cSectionTitle {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
        display: flex;
        align-items: center;
      }
      .desc {
        color: rgb(102, 102, 102);
        font-size: 14px;
        line-height: 21px;
      }
    }

    .subSection {
      padding: 10px 6px 10px 48px;
      display: flex;
      justify-content: space-between;
      &:hover {
        background-color: rgb(243, 243, 245);
      }
    }

    .subSectionTitle {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin: 0 0 8px 0;
    }

    .subSectionAbs {
      color: rgb(102, 102, 102);
      font-size: 14px;
      line-height: 21px;
    }
  }

  .collaspseSectionSingle {
    padding: 10px 6px 10px 48px;
    .cSectionTitle {
      .title {
        margin: 0 0 8px 0;
      }
    }
  }

  :global(.ant-collapse-header) {
    padding: 10px 6px !important;
    border-radius: 0 !important;
    align-items: center !important;

    &:hover {
      background-color: rgb(243, 243, 245);
    }
  }

  :global(.ant-collapse-content-box) {
    padding: 0 !important;
  }
}

.summaryTips {
  //position: absolute;
  //right: -145px;
  position: fixed;
  right: 2vw;
  transition: all ease 300ms;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  border: 1px solid rgb(229, 231, 235);
  width: 130px;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 6px;
  display: none;

  .bold {
    font-weight: 700;
    line-height: 24px;
  }

  .line {
    display: flex;
    align-items: center;
    line-height: 24px;
  }

  span {
    color: var(--ant-color-primary);
  }
}

.label {
  font-size: 14px;
  font-weight: normal;
  margin: 12px 0;
  b {
    color: red;
    margin-left: 4px;
  }
}

@media only screen and (max-device-width: 1024px) {
  /* 在此添加针对iPad的CSS样式 */
  .construct {
    .new {
      width: 96%;
      padding: 12px;

      .progress {
        //padding: 12px 8px;
        padding: 0;
        :global(.ant-steps-vertical) {
          flex-direction: row;
          transform: scale(0.7) translateX(-60px);
        }
      }
      .step1 {
        .form {
          padding: 0px;
        }
      }

      .content {
        padding: 0 12px;
        h4 {
          margin: 0 0 10px 0;
        }
      }

      .step2 {
        .titles {
          padding: 15px 10px;
        }

        .item {
          padding: 5px 2px;
          margin-bottom: 12px;
        }
      }
    }
  }
}

/* 为iPhone设备设置特殊样式 */
@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* 在此添加针对iPhone的CSS样式 */
}
