.footer {
  width: 100%;
  max-width: 90vw;

  .footerTop {
    display: flex;
    justify-content: center;
    margin: 48px 0;

    .box {
      margin: 0 32px;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .imgBox {
      border: 1px solid rgb(229, 231, 235);
      background-color: #fff;
      width: 160px;
      max-width: 30vw;
      border-radius: 4px;
      margin-bottom: 16px;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        width: 158px;
        height: 158px;
        max-width: 100%;
        height: auto;
      }
    }
  }
  .aboutUs {
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgb(107, 114, 128);
    border-top: 1px solid rgba(17, 24, 39, 0.1);
    padding-top: 20px;
  }
}
