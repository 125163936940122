.floatToolbar {
  cursor: pointer;
  z-index: 999;
  color: #666;
  position: absolute;
  padding: 8px 12px;
  border-radius: 4px;
  background: #fff;
  border: 1px solid rgb(234, 234, 234);
  white-space: nowrap;
  box-shadow: rgba(13, 20, 33, 0.13) 0px 3px 15px -3px;
  width: 350px;

  .toolbarRow {
    display: flex;
    align-items: center;
  }

  .floatLeft {
    display: flex;
  }

  .floatRight {
    border-left: 1px solid #eee;
    margin-left: 5px;
    padding: 0 4px 0 4px;
    display: flex;
  }

  .floatBarItem {
    padding: 4px 4px;
    color: #333;
    display: flex;
    align-items: center;
    width: 24px;
    height: 24px;
    &:hover {
      border-radius: 4px;
      background: #f5f5f5;
    }

    .fill3 {
      fill: #333;
    }
    .stoke {
      fill: none;
      stroke: #444;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
    }

    .thin {
      fill: none;
      stroke: #444;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1;
    }
  }

  .floatBarButton {
    padding: 2px 4px;
    border: 1px solid rgba(201, 201, 204, 0.48);
    margin: 0 4px;
  }
  .contentRow {
    border-top: 1px solid rgba(201, 201, 204, 0.48);
    padding: 6px 0;
    margin: 6px 0 0 0;

    .label {
      font-size: 12px;
      color: #8e8e8e;
      padding: 5px 0 0 0;
    }

    .resultBox {
      max-height: 200px;
      overflow-y: auto;
    }
    .result {
      color: #333;
      word-wrap: break-word;
      white-space: pre-wrap;
      word-break: break-all;
      padding-bottom: 10px;
    }

    .footerBtn {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      padding: 10px 0 0 0;
      background: #fafafa;

      .left {
        margin-right: 15px;
      }
    }
  }
}

.aiWrite {
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: #ffb347;
  font-weight: 500;
}
