.header {
  width: 100%;
  height: 55px;
  background: #fafafa;
  padding: 0 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999;
}

.download {
  width: 100%;
  height: 100vh;
  background: #fff;
  box-sizing: border-box;
  padding: 65px 12px 50px 12px;

  .tips {
    line-height: 28px;
    margin: 10px 0;
  }

  .footer {
    display: flex;
    margin-top: 24px;
    justify-content: center;
    position: fixed;
    bottom: 0;
    z-index: 999;
    background-color: #fff;
    width: 100vw;
    padding: 10px 0;
    left: 0;
    right: 0;
  }
}
