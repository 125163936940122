.ppt {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f2f5;
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    width: 100%;
    height: 55px;
    background: #fafafa;
    padding: 0 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    align-items: center;
    //justify-content: space-between;
    justify-content: center;
    flex-shrink: 0;
    color: #00ce72;
    font-size: 18px;
    font-weight: bold;
  }
}

.ppt .new {
  width: 62.5%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  // justify-content: stretch;
  flex: 1;
  overflow: auto;
  background-image: url("../../assets/stetBg.png");
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 16px;

  h1 {
    margin-top: 30px;
  }

  .flexTitle {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    margin-right: 20px;
    flex-shrink: 0;
  }

  .step {
    margin-top: 10px;
    //background-color: white;
  }

  .progress {
    padding: 20px 70px 0;
  }

  .content {
    padding: 20px 50px;

    h4 {
      color: #1a1b4c;
      line-height: 23px;
      margin: 26px 0 20px;
      width: 100%;
      text-align: center;
    }
  }

  .paging {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 82px;
    align-items: center;

    button {
      margin-left: 16px;
    }
  }

  .step1 {
    .form {
      //background-color: rgba(242, 242, 245, 0.4);
      padding: 32px;
    }

    .line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      :global(.ant-form-item) {
        width: 346px;
      }
    }

    .keywords {
      :global(.ant-select-selection-item) {
        font-weight: bold;
        color: #24be58;
        background: #caefd7;
      }
    }

    .tryout {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: 14px;
      font-weight: 600;
      color: #010235;
      margin: 0 auto;
      display: flex;
      align-items: flex-start;
      line-height: 22px;
      justify-content: flex-start;

      img {
        width: 12px;
        height: auto;
        display: block;
        margin: 4px 0 0 4px;
      }

      .klist {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 1;

        .k {
          background: #fff;
          border-radius: 11px;
          border: 1px solid #d6d6d64d;
          font-size: 14px;
          font-weight: 600;
          color: #f7941d;
          padding: 0 12px;
          margin-left: 12px;
          margin-bottom: 12px;
          cursor: pointer;
        }
      }
    }

    .submit {
      margin-top: 40px;

      :global(.ant-form-item-control-input-content) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }

  .step2 {
    .inner {
      background: rgba(242, 242, 245, 0.4);
      border-radius: 3px;
      padding: 30px 32px;
      width: 100%;

      .itemWrap {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #ffffff;
        border-radius: 3px;
        padding: 15px;
        margin-bottom: 8px;
        border: 1px solid transparent;

        &:hover {
          box-shadow: 0 1px 4px rgba(68, 90, 116, 0.25);
        }

        &.focus {
          border-color: rgba(0, 145, 255, 0.66);
        }

        &.dragging {
          border-color: #f7941d;
          background-color: #f7951d21;
        }

        .icon {
          color: #999;
          margin-top: 12px;
        }

        &:hover {
          .icon {
            color: #010235;
          }
        }

        .ss {
          display: flex;
          flex: 1;
          flex-direction: column;

          ul {
            margin-top: 5px;
            color: #999;
          }

          li {
            margin-top: 4px;
          }
        }

        .input,
        .shitem {
          display: flex;
          flex: 1;
          align-items: center;

          input {
            flex: 1;
          }

          .ic {
            display: none;
            flex-shrink: 0;
          }

          &:hover .ic {
            display: block;
            opacity: 0.5;
          }

          .ic:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .step3 {
    padding: 46px 0 0 0;

    .title {
      margin-bottom: 30px;
    }

    .titleDesc {
      color: #666;
      font-size: 12px;
    }

    .templateBox {
      display: flex;
      flex-wrap: wrap;
    }

    .template {
      background-color: #fff;
      border: 1px solid #ebe3ff;
      border-radius: 12px;
      cursor: pointer;
      list-style: none;
      overflow: hidden;
      position: relative;
      width: calc(33% - 8px);
      margin-bottom: 20px;
      margin-right: 12px;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }

    .checkTemplate {
      background-color: #fff;
      border: 1px solid #00ce72;
      border-radius: 12px;
      cursor: pointer;
      list-style: none;
      overflow: hidden;
      position: relative;
      width: calc(33% - 8px);
      margin-bottom: 20px;
      margin-right: 12px;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }

    .templateImg {
      position: relative;
      width: 100%;
      height: auto;

      .img {
        width: 100%;
        height: auto;
      }
    }

    .templateTitle {
      display: flex;
      align-items: center;
      color: #161724;
      font-size: 18px;
      height: 66px;
      line-height: 66px;
      overflow: hidden;
      padding: 0 12px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .select {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 11;
      //background-color: rgba(0, 0, 0, 0.5);
      //border: 1px solid #00CE72;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding-top: 163px;

      .ml20 {
        margin-left: 20px;
      }
    }

    .selectCircle {
      border-radius: 80px;
      background-color: #fff;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .paging {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 82px;
    align-items: center;

    button {
      margin-left: 16px;
    }
  }
}

.successModal {
  :global(.ant-btn-primary) {
    background-color: #f7941d;

    &:hover {
      background-color: #ffb347 !important;
    }
  }
}

.wenxianDesc {
  font-size: 12px;
  color: #999;
  padding: 0 0 0 11px;
  margin: 3px 0 0 0;
}

.pptConfirm {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;

  .right {
    margin-left: 20px;
  }
}

.pptDesc {
  font-size: 12px;
  color: #666;
  max-width: 300px;
  word-break: break-all;
}

.pptIcon {
  margin: 0 4px 0 0;
}

@media only screen and (max-device-width: 1024px) {
  /* 在此添加针对iPad的CSS样式 */
  .ppt {
    padding: 0 12px;

    .new {
      width: 100%;
      padding: 0 12px;

      .progress {
        padding: 12px 8px;

        :global(.ant-steps-vertical) {
          flex-direction: row;
          transform: scale(0.7) translateX(calc(-50% - 60px));
          margin-left: 50%;
        }
      }

      .content {
        width: 100%;
        padding: 0 12px;

        h4 {
          margin: 0 0 10px 0;
        }
      }

      .step2 {
        .titles {
          padding: 15px 10px;
        }

        .item {
          padding: 5px 2px;
          margin-bottom: 12px;
        }
      }

      .step3 {
        padding: 0;

        .title {
          margin-bottom: 30px;
        }

        .titleDesc {
          color: #666;
          font-size: 12px;
        }

        .templateBox {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 12px;
          width: 100%;
        }

        .template {
          width: 100%;
          background-color: #fff;
          border: 1px solid #ebe3ff;
          border-radius: 12px;
          cursor: pointer;
          list-style: none;
          overflow: hidden;
          position: relative;
        }

        .checkTemplate {
          width: 100%;
          background-color: #fff;
          border: 1px solid #00ce72;
          border-radius: 12px;
          cursor: pointer;
          list-style: none;
          overflow: hidden;
          position: relative;
        }

        .templateImg {
          position: relative;
          width: 100%;
          height: auto;

          .img {
            width: 100%;
            height: auto;
          }
        }

        .templateTitle {
          display: flex;
          align-items: center;
          color: #161724;
          font-size: 12px;
          height: 40px;
          line-height: 66px;
          overflow: hidden;
          padding: 0 12px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .select {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          z-index: 11;
          //background-color: rgba(0, 0, 0, 0.5);
          //border: 1px solid #00CE72;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          width: 100%;
          height: 100%;
          padding-top: 163px;

          .ml20 {
            margin-left: 20px;
          }
        }

        .selectCircle {
          border-radius: 80px;
          background-color: #fff;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
