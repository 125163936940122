.description {
  width: 600px;
}

.leftPopoverTitle {
  font-size: 16px;
  font-weight: 700;
  margin-top: 10px;
  color: #111;
}
.leftPopoverContent {
  font-size: 14px;
  margin-top: 5px;
  color: #888;
  text-indent: 28px;
}
.networkCertification {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}
