.userInfo {
  display: flex;
  flex-direction: row;
  padding: 10px;
  justify-content: center;
  align-items: center;
  margin: 0 6px;
  cursor: pointer;
  margin-top: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;

  .av {
    flex-shrink: 0;
    transition: all 0.2s linear;
  }

  .info {
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      color: #010235;
      display: inline-block;
      white-space: nowrap;
    }

    .progress {
      margin-left: 2px;
      :global(.ant-progress-inner) {
        height: 2px;
        border-radius: 100px;
      }

      :global(.ant-progress-bg) {
        border-radius: 100px;
        height: 100% !important;
      }
    }

    .count {
      font-size: 12px;
      color: gray;
      margin-top: -8px;
      white-space: nowrap;
    }

    .writeIcon {
      width: 10px;
      display: block;
      margin: 0px 0 0px 2px;
      transform: translateY(7px);
    }
  }
}

.loginBtn {
  margin: 0 20px;
  margin-top: auto;
}

:global(.ant-menu-inline-collapsed) {
  .userInfo {
    .info {
      display: none;
    }
  }
}
:global(.ant-layout-sider-collapsed) {
  .info {
    display: none;
  }
  .articleLogo {
    overflow: hidden;
    width: 27px;
    height: 27px;
    object-fit: cover;
    object-position: left;
    margin-left: 27px;
  }
}
