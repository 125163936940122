html,
body,
#app {
  height: 100%;
}
.login-modal {
  .ant-modal-content {
    padding: 0 !important;
  }
}

.custom-input {
  position: relative;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid rgba(0, 24, 46, 0.33);
  padding: 0 5px;
  font-size: 16px;
}

.custom-input:after {
  position: absolute;
  content: " ";
  display: block;
  width: calc(100% - 6px);
  height: 2px;
  background-color: #f7941d;
  bottom: -1px;
  left: 3px;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  opacity: 0;
}

.custom-input.ant-input-affix-wrapper-focused {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.custom-input.ant-input-affix-wrapper-focused:after {
  opacity: 1;
}

.custom-input .ant-input {
  padding: 0 15px !important;
  height: 100%;
}

.custom-input .ant-input::placeholder {
  font-size: 14px;
}

.custom-input .ant-input::-webkit-input-placeholder {
  color: rgba(0, 24, 46, 0.33);
}

.custom-input .ant-input-suffix {
  margin-right: 16px;
}

.custom-input.ant-input-password span[aria-label="eye-invisible"] {
  opacity: 0.25;
}

.custom-form .custom-btn {
  margin-top: 1px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 600;
  border: none;
}
.custom-form .custom-btn[disabled]:hover {
  background: #f7941d;
  color: #fff;
  opacity: 0.7;
}

.flex {
  display: flex;
  //align-items: center;
}

.ml4 {
  margin-left: 4px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt40 {
  margin-top: 40px;
}

.cursor-pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: rgb(0 24 46 / 20%) 0px 2px 4px 0px;
}

.mr10 {
  margin-right: 10px;
}
a:hover {
  color: #ffb347 !important;
}
