.userInfo {
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin: 0 6px;
  cursor: pointer;
  margin-top: auto;
  border: 1px solid #e0e0e0;
  border-radius: 10px;

  .av {
    margin-right: 10px;
    flex-shrink: 0;
    transition: all 0.2s linear;
  }

  .info {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .name {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 18px;
      color: #010235;
      display: inline-block;
      white-space: nowrap;
    }

    .count {
      font-size: 12px;
      color: gray;
      margin-top: -8px;
      white-space: nowrap;
    }
  }
}

.articleLogo {
  cursor: pointer;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  background: #fff;
  align-items: center;
  /* margin: 40px 20px 37px 20px; */
  width: auto;
  transition: all 0.2s linear;
  height: 50px;
  cursor: pointer;
  object-fit: contain;
  margin: 40px 18px 34px 18px;
}

.it {
  color: #000000e0;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5714285714285714;
  cursor: pointer;
}

.file {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    font-size: 12px !important;
    box-shadow: 0 1px 4px rgb(68 90 116 / 25%);
  }
}

.about {
  padding: 20px 10px 0px 10px;
  cursor: pointer;
  color: #666;
  display: flex;
  justify-content: center;
}

.aboutIcon {
  margin-right: 4px;
}

:global(.ant-layout-sider-collapsed) {
  .articleLogo {
    overflow: hidden;
    width: 29px;
    height: 29px;
    object-fit: cover;
    object-position: left;
    margin-left: 26px;
  }
}

:global(.ant-menu-item-selected) {
  font-weight: 600 !important;
}
:global(.ant-layout-sider-children) {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  /* 在此添加针对iPad的CSS样式 */
}

/* 为iPhone设备设置特殊样式 */
@media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  /* 在此添加针对iPhone的CSS样式 */
}
