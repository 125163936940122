.leftPopoverTitle {
    font-size: 18px;
    font-weight: 500;
    margin: 32px 0 16px;
    color: #111;
}
.leftPopoverContent {
    font-size: 14px;
    margin-top: 5px;
    color: #888;
    text-indent: 28px;
    line-height: 22px;
}
