.agreement {
  max-width: 1080px;
  margin: 0 auto;
  padding: 16px 20px;
  word-break: break-all;
  white-space: pre-line;
  word-wrap: pre-line;
  line-height: 1.5;
}

.agreementTitle {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  margin: 16px 0;
}
