.result {
  padding: 30px 0 0 0;
  flex: 1;
  overflow: auto;

  .resultPage {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.08);
    padding: 60px 90px;
    width: 900px;
    margin: 0 auto;
    position: relative;
  }

  .pageTitle {
    margin-top: 0px;
    margin-bottom: 40px;
    text-align: center;
    line-height: 150%;
    font-size: 36px;
  }

  .chapterTitle {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 20px 0;
  }
  .subHeading {
    font-size: 16px;
    margin: 10px 0;
    font-weight: bold;
  }

  .content {
    white-space: pre-line;
    word-break: break-all;
    line-height: 32px;
    font-size: 16px;
    span {
      vertical-align: text-bottom;
      font-size: 12px;
    }
  }

  .pay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .hideTop {
      width: 100%;
      height: 150px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }

    .payContent {
      width: 100%;
      height: 150px;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #24be58;
      border-radius: 0 0 10px 10px;
      cursor: pointer;

      .payBtn {
        width: 185px;
        height: 52px;
        background-size: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        background-color: #caefd7;
        color: #24be58;
        flex-direction: column;
        margin: 0 auto;
      }

      .payTip {
        margin-top: 8px;
        text-align: center;
        display: flex;
        align-items: center;
      }

      .extra {
        display: flex;
        margin-top: 6px;

        .right {
          margin-left: 10px;
        }
      }

      .fuwuLink {
        text-decoration: none;
        color: #24be58;

        &:hover {
          color: #24be58 !important;
        }
      }
    }
  }
}

.demoList {
  background: #eef8f5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;

  width: 500px;
  margin: 0 auto;
  margin-bottom: 20px;

  :global(.slick-dots-left) {
    display: none;
    visibility: hidden;
    opacity: 0;
  }
}

@media only screen and (max-device-width: 1024px) {
  /* 在此添加针对iPad的CSS样式 */
  .result {
    .resultPage {
      width: 100%;
      padding: 10px 15px;
    }

    .demoList {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
