.recommendContentBox {
  flex: 1;

  display: flex;
  flex-direction: column;
  max-width: 800px;
  overflow: hidden;
  position: relative;
  border-left: 1px solid #ddd;
  padding: 0 20px;
  display: flex;
  flex-direction: column;

  .titleBox {
    position: sticky;
    top: 0;
    font-weight: 700;
    font-size: 14px;
    color: #1f1f1f;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;

    .title {
      position: relative;
      width: fit-content;
    }
    .underline {
      position: absolute;
      left: 0;
      width: 100%;
      bottom: -16px;
      height: 6px;
      border-radius: 6px;
      background-color: #8e8e8e;
    }

    .close {
      cursor: pointer;
    }
  }

  .result {
    flex: 1;
    box-sizing: border-box;
    padding: 40px 0;

    .resultContent {
      background-color: #fff;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      padding: 10px 15px;
      white-space: break-spaces;
    }
  }
}
